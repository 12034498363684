var exports = {};
exports = convert;

function convert(test) {
  if (test == null) {
    return ok;
  }

  if (typeof test === "string") {
    return typeFactory(test);
  }

  if (typeof test === "object") {
    return "length" in test ? anyFactory(test) : allFactory(test);
  }

  if (typeof test === "function") {
    return test;
  }

  throw new Error("Expected function, string, or object as test");
} // Utility assert each property in `test` is represented in `node`, and each
// values are strictly equal.


function allFactory(test) {
  return all;

  function all(node) {
    var key;

    for (key in test) {
      if (node[key] !== test[key]) return false;
    }

    return true;
  }
}

function anyFactory(tests) {
  var checks = [];
  var index = -1;

  while (++index < tests.length) {
    checks[index] = convert(tests[index]);
  }

  return any;

  function any() {
    var index = -1;

    while (++index < checks.length) {
      if (checks[index].apply(this, arguments)) {
        return true;
      }
    }

    return false;
  }
} // Utility to convert a string into a function which checks a given node’s type
// for said string.


function typeFactory(test) {
  return type;

  function type(node) {
    return Boolean(node && node.type === test);
  }
} // Utility to return true.


function ok() {
  return true;
}

export default exports;